import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55')
];

export const server_loads = [];

export const dictionary = {
		"/": [10],
		"/(main)/(app)/about": [18,[3,4]],
		"/(signup)/activate": [43,[8]],
		"/(main)/admin": [34,[3,6]],
		"/(main)/admin/bans": [39,[3,6]],
		"/(main)/admin/donations": [40,[3,6]],
		"/(main)/admin/(site)/giveaways": [35,[3,6,7]],
		"/(main)/admin/members": [41,[3,6]],
		"/(main)/admin/(site)/news": [36,[3,6,7]],
		"/(main)/admin/(site)/profiles": [37,[3,6,7]],
		"/(main)/admin/(site)/scrim": [38,[3,6,7]],
		"/(main)/admin/serverchat": [42,[3,6]],
		"/(main)/(app)/bans": [19,[3,4]],
		"/(main)/(app)/(forum)/board/[slug]": [13,[3,4,5]],
		"/(main)/(app)/(forum)/board/[slug]/new_topic": [14,[3,4,5]],
		"/(blank)/callback/steam": [11,[2]],
		"/(blank)/callback/steam/link": [12,[2]],
		"/(main)/(app)/contact": [20,[3,4]],
		"/(main)/(app)/cookies": [21,[3,4]],
		"/(main)/(app)/(forum)/forum": [15,[3,4,5]],
		"/games/julkalender/[slug]": [54],
		"/games/wheel/[slug]": [55],
		"/(main)/(app)/inventory": [22,[3,4]],
		"/(signup)/login": [44,[8]],
		"/(main)/(app)/match/[slug]": [23,[3,4]],
		"/(main)/(app)/(forum)/new_board": [16,[3,4,5]],
		"/(signup)/passwordless": [45,[8]],
		"/(main)/(app)/profile/edit": [26,[3,4]],
		"/(main)/(app)/profile/[slug]": [24,[3,4]],
		"/(main)/(app)/profile/[slug]/inventory": [25,[3,4]],
		"/(signup)/register": [46,[8]],
		"/(signup)/renew": [47,[8]],
		"/(signup)/reset": [48,[8]],
		"/(main)/(app)/scrim": [27,[3,4]],
		"/(main)/(app)/scrim/[slug]": [28,[3,4]],
		"/(main)/(app)/servers": [29,[3,4]],
		"/(signup)/settings": [49,[8,9]],
		"/(signup)/settings/notifications": [50,[8,9]],
		"/(signup)/settings/security": [51,[8,9]],
		"/(main)/(app)/stadgar": [30,[3,4]],
		"/(signup)/startpasswordless": [52,[8]],
		"/(signup)/startpasswordreset": [53,[8]],
		"/(main)/(app)/sverok": [31,[3,4]],
		"/(main)/(app)/terms": [32,[3,4]],
		"/(main)/(app)/(forum)/topic/[slug]": [17,[3,4,5]],
		"/(main)/(app)/tournaments": [33,[3,4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';